import { useContext, createContext, useState, useEffect } from "react";
import {
  getAllActivitySites,
  getAllTopCountries,
  getPastWishlistActivities,
  getTopCities,
  getUserWishlistFolders,
} from "../API_HELPERS/apiHelpers";
import { useAuth } from "./AuthContextProvider";

const globalDataCtx = createContext({});
export const useGlobalDataCtx = () => useContext(globalDataCtx);

const GlobalDataProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedTopCountries, setFetchedTopCountries] = useState([]);
  const [fetchedTopCities, setFetchedTopCities] = useState([]);
  const [fetchedTopActivitySites, setFetchedTopActivitySites] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [searchListName, setSearchListName] = useState("");
  const [folders, setFolders] = useState([]);
  const [fetchedPastWishlist, setFetchedPastWishlist] = useState([]);
  const { loginStatus } = useAuth();
  //...................................................2 month calender data.......
  function cuttOffTimeGet(timeStr) {
    // Helper function to parse the time string and return a Date object
    function parseTime(timeStr) {
      const [time, period] = timeStr.split(' ');
      const [hours, minutes] = time.split(':').map(Number);
      
      let adjustedHours = hours;
      if (period === 'PM' && hours !== 12) {
        adjustedHours += 12;
      } else if (period === 'AM' && hours === 12) {
        adjustedHours = 0;
      }
      
      return new Date(1970, 0, 1, adjustedHours, minutes); // Use a date with 1970-01-01 to just represent time
    }
  
    // Helper function to format the time back into the "h:mm AM/PM" format
    function formatTime(date) {
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, '0');
      
      const period = hours >= 12 ? 'PM' : 'AM';
      
      if (hours > 12) {
        hours -= 12;
      } else if (hours === 0) {
        hours = 12;
      }
      
      return `${hours}:${minutes} ${period}`;
    }
  
    const date = parseTime(timeStr);
    
    // Subtract one hour
    date.setHours(date.getHours() - 1);
    
    return formatTime(date);
  }

  const [firstMonthBokunData,setfirstoneMonthData]=useState(null)
  const [secondMonthBokunData,setSecondMonthData]=useState(null)

  const getbokunSlotData=(date)=>{

 
    let filterdata1 = firstMonthBokunData?.filter(ele => ele._id === date);
    let filterdata2 = secondMonthBokunData?.filter(ele => ele._id === date);
    if (filterdata1?.length > 0) {


        const fdata=filterdata1[0]?.tourDetails.map((ele,id)=>{
          return(
            {
              // _id: "66b09e30124e50c40fb95d03",
              startTimeId:ele?.startTimeId,
              tourDate:date ,
              changePrice: 0,
              frontrowseat: 0,
              cutoffTime: cuttOffTimeGet(ele._id),
              remeningUser: ele.remainingUsers,
              time: ele._id,
              status: ele.status
          })

        })
       
 
      return fdata;
    }
    if (filterdata2?.length > 0) {

      const fdata=filterdata2[0]?.tourDetails.map((ele,id)=>{
     
        return(
          {
            // _id: "66b09e30124e50c40fb95d03",
            startTimeId:ele?.startTimeId,
            tourDate:date ,
            changePrice: 0,
            frontrowseat: 0,
            cutoffTime:cuttOffTimeGet(ele._id),
            remeningUser: ele.remainingUsers,
            time: ele._id,
            status: ele.status
        })

      })
     

    return fdata;
    }

    return [];}

//...........................................................

  const fetchAllTopCountries = async () => {
    try {
      setIsLoading(true);
      const res = await getAllTopCountries();
      if (res && res?.status) {
        // setFetchedTopCountries(
        //   res?.data?.sort(
        //     (item1, item2) => item1?.topPriority - item2?.topPriority
        //   )
        // );
      } else {
      }
    } catch (error) {
    }
    setIsLoading(false);
  };
  const fetchAllTopCities = async () => {
    try {
      setIsLoading(true);
      const res = await getTopCities();
      if (res && res?.status) {
       setFetchedTopCities(res.data.topCity)
       setFetchedTopCountries(res.data.topCountry)
       setFetchedTopActivitySites(res.data.topactivitySiteData)

        // setFetchedTopCities(
        //   res?.data?.sort((a, b) => a?.topPriority - b?.topPriority)
        // );
      
        setIsLoading(false);

      } else {
        setIsLoading(false);
      }
    } catch (error) {
    setIsLoading(false);
    }
  };
  const fetchAllTopActitvitySites = async () => {
    try {
      setIsLoading(true);
      const res = await getAllActivitySites();
      if (res && res?.status) {
        // setFetchedTopActivitySites(res?.data);
      } else {
      }
    } catch (error) {
    }
    setIsLoading(false);
  };

  const fetchWishListFolders = async () => {
    try {
      setIsLoading(true);
      const res = await getUserWishlistFolders();
      if (res && res?.status) {
        setFolders(res?.data);
      } else {
      }
    } catch (error) {
    }
    setIsLoading(false);
  };
  const fetchPastWishlist = async () => {
    try {
      setIsLoading(true);
      // setFolderId(id);

      const res = await getPastWishlistActivities();
      if (res && res?.status) {
        setFetchedPastWishlist(res?.data);
      } else {
      }
    } catch (error) {
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // fetchAllTopCountries();
    fetchAllTopCities();
    fetchAllTopActitvitySites();
  }, []);

  useEffect(() => {
    if (loginStatus) {
      fetchWishListFolders();
      fetchPastWishlist();
    } else {
      setFolders([]);
      setFetchedPastWishlist([]);
    }
  }, [loginStatus]);

  return (
    <globalDataCtx.Provider
      value={{
        isLoading,
        newisLoading:isLoading,
        fetchAllTopCountries,
        fetchAllTopCities,
        fetchAllTopActitvitySites,
        fetchedTopCountries,
        fetchedTopCities,
        fetchedTopActivitySites,
        searchData,
        setSearchData,
        setSearchListName,
        searchListName,
        folders,
        setFolders,
        fetchWishListFolders,
        fetchPastWishlist,
        fetchedPastWishlist,
        setFetchedPastWishlist,
        setIsLoading,
        getbokunSlotData,
        firstMonthBokunData,
        setfirstoneMonthData,
        setSecondMonthData,
    

      }}
    >
      {children}
    </globalDataCtx.Provider>
  );
};

export default GlobalDataProvider;
