import { useEffect, useState } from "react";
// import mainbody_bg from "../../assets/Images/reviewBannerImage";
import mainbody_bg from "../../assets/Images/reviewBannerImage.jpg"


import MainLoader from "../../components/Loaders/MainLoader";


function ReviewPage() {
  const [isLoading, setIsLoading] = useState(false);
 


  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

  }, []);

  return (
    <>
      <MainLoader isLoading={isLoading} />
      <section
        className="banerInformSection"
        style={{ backgroundImage: `url('${mainbody_bg}')` }}
      >
        <div className="banerInformDiv">
          <p className="sitemapPara">
           Reviews
          </p>
        </div>
      </section>
      <section className="allToursSection mb-3">
        <h1 className="allToursPara">
        All verified Reviews
        </h1>
        <div className="container">
         <h2>Reviews displayed on this website is made up from confirmed and verified  reviews displayed on other worldwide booking and review sites including TripAdvisor, viator, getyourguide, klook, musement, Google and other reputable sites that display reviews about a specific tour

Fresh reviews are verified reviews from customers that booked directly on the thingstodooo platform and chose to review the tour 

All reviews displayed on a tour, attraction or experience  is reviews for that specific  product and not the company operating the tour in general</h2>
        </div>
      </section>
    </>
  );
}

export default ReviewPage;
